<template>
  <div class="password-forgotten footer-margin">

    <div class="container-fluid blank">
      <div id="password-forgotten-row" class="row justify-content-center align-items-center blank-section">
        <div id="password-forgotten-column">
          <div id="password-forgotten-box" class="col-12">

            <h3 class="text-center text-info">Change password</h3>

            <div class="form-group text-center">
              <span class="text">Forgotten your password? - No problem. Simply set a new password and get started right away!</span>
            </div>

            <div class="form-group mb-2">
              <div class="inner-addon left-addon">
                <i class="glyphicon material-icons icon" aria-hidden="true" data-wg-notranslate="">lock</i>
                <input type="password" class="form-control form-control-rtl" @blur="validatePasswordInput" v-model="credentials.password" placeholder="password">
                <span class="validation-error" v-show="showPasswordError">Enter a password. Please note the requirements below.</span>
              </div>
            </div>

            <div class="mb-2 pw-req">At least 8 characters, 1 upper case letter, 1 lower case letter, 1 number</div>

            <div class="form-group">
              <div class="inner-addon left-addon">
                <i class="glyphicon material-icons icon" aria-hidden="true" data-wg-notranslate="">lock</i>
                <input type="password" class="form-control form-control-rtl" @blur="validatePasswordRepeatInput" v-model="credentials.passwordRepeat" placeholder="Repeat password">
                <span class="validation-error" v-show="showPasswordRepeatError">The passwords do not match.</span>
              </div>
            </div>

            <div class="form-group">
              <button @click="handleReset" style="width: 100%;" class="btn btn-fill btn-hover btn-focus-design">Change password</button>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { csrf, resetPassword } from '@/api/auth';
  import { validatePassword } from '@/utils/validate';
  import { showLoader, hideLoader } from '@/utils/helpers';

  export default {
    name: 'password-forgotten',
    data() {
      return {
       credentials: {
        token: '',
        password: "",
        passwordRepeat: "",
      },
      showPasswordError: false,
      showPasswordRepeatError: false,
      loader: false,
    };
  },
  mounted() {
    if(this.$route.params.token){
      console.log(this.$route.params.token);
      this.credentials.token = this.$route.params.token;
    }
  },
  methods: { 
    showLoader,
    hideLoader,
    validatePassword,
    validatePasswordInput(){
      if(!this.validatePassword(this.credentials.password)){
        this.showPasswordError=true;
        return false;
      }else{
        this.showPasswordError=false;
        return true;
      }
    },
    validatePasswordRepeatInput(){
      if(this.credentials.password != this.credentials.passwordRepeat){
        this.showPasswordRepeatError=true;
        return false;
      }else{
        this.showPasswordRepeatError=false;
        return true;
      }
    },
    handleReset() {

      var passwordValid = this.validatePasswordInput();
      var passwordRepeatValid = this.validatePasswordRepeatInput();
      if(!passwordValid || !passwordRepeatValid){
        return;
      }

      this.loader = this.showLoader(this.loader);
      csrf().then(() => {
        resetPassword(this.credentials).then(() => {
         this.$notify({
          duration: 2500,
          title: "Passwort erfolgreich geändert!",
          text: "Dein Passwort wurde erfolgreich geändert. Du kannst dich jetzt mit deinem neuen Passwort anmelden."
        });

         this.$router.push({name:'Login'});

       })
        .catch(() => {
         this.$notify({
          type: 'error',
          duration: 2500,
          title: "Fehler beim Zurücksetzen des Passworts!",
        });
       }).finally(()=>{
         this.loader = this.hideLoader(this.loader);
       });
     });
    },
  }
};
</script>

<style lang="scss" scoped="">
  @import '@/scss/_variables.scss';

  .validation-error {
    color: red;
  }

  .password-forgotten {
    position: relative;
    background: #fff;
    padding-top: 30px;
    padding-bottom: 40px;

    .blank{
      display: flex;
      align-items: center;
    }

    .blank-section {
      width: 500px;
      margin: 0 auto;
      text-align: center;
    }

    .container-fluid {
      max-width: 1000px;

      #password-forgotten-column{

        @media (max-width: 575px) {
          padding: 0px 50px;
        }

        @media (max-width: 400px) {
          padding: 0px 30px;
        }

      }


      h3.text-info {
        color: #00183E !important;
        margin-bottom: 30px;
      }

      .form-check{
        margin-bottom: 20px;      
      }

      .form-group {
        margin-bottom: 30px;

        .left-addon .glyphicon {
          left: 0px;
        }

        .inner-addon {
          position: relative;
          width: 100%;
        }

        .inner-addon .glyphicon {
          position: absolute;
          padding: 9px;
          pointer-events: none;
          color: #157FAC;
        }

        .left-addon input {
          padding-left: 40px;
        }


      }
    }
  }

  .pw-req {
    font-size: 12px;
    text-align: left;
  }
</style>
